import React from 'react'
import { ModalFooter } from "react-bootstrap"

const Footer = ()  => {
    return (
    <div style={{backgroundColor:"#007bff", color:"white", marginTop:'30px', position:"fixed", bottom: "0px", width:"100%", height:"50px", padding:"10px"}}>
        CONTACT : P.K.Medappa@tilburguniversity.edu
    </div>
    )
}

export default Footer